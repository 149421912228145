<template lang="pug">
  header#header.header
    div.container
      div.header__inner
        a(v-scroll-to="'#header'")(@click='clickBtn').logo
          img(src="~@/assets/images/logo.svg" alt="Logo")

        navigation-block

        button.header__burger(@click='toggleMenuMobile')
          span

</template>

<script>

export default {
  name: 'HeaderBlock',
  components: {
    NavigationBlock: () => import('@/components/NavigationBlock.vue'),
  },
  data: () => {
    return {
      activeEl: 0,
    }
  },
  beforeCreate() {
    window.addEventListener('scroll', function () {
      let scrollPos = window.scrollY
      let header = document.querySelector('.header')

      if (header && scrollPos > 10) {
        header.classList.add("active")
      } else {
        header.classList.remove("active")
      }
    })
  },
  methods: {
    clickBtn () {
      let menuItem = document.querySelectorAll('.navigation__item')
      for(let i = 0; i < menuItem.length; i++) {
        menuItem[i].classList.remove('active');
        menuItem[0].classList.add('active');
      }
    },
    toggleMenuMobile() {
      document.querySelector('.menu__items').classList.toggle('menu__items--active')
      document.querySelector('.header__burger').classList.toggle('header__burger--active')
    }
  }
}

</script>
